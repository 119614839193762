import { formatUnits } from "ethers/lib/utils";
import moment from "moment";

export const getWallet = async () => {
    if (!window.ethereum?.request) {
        window.open("https://metamask.io/download.html")
        console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        return;
    }
    
    const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
    });    

    return accounts
}

export const formatEther = (val) => {

    let num = 0
    if ( Number(formatUnits(val)).toString().includes(".") ) {
        num = Number(formatUnits(val)).toFixed(8)
    }
    else {
        num = Number(formatUnits(val))
    }

    var parts = num.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");

    // return num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (bigNumber) => {
    let time = bigNumber.toNumber() * 1000
    return moment(time).format("YYYY-MM-DD")
}

export const switchChain = async () => {
    if (!window.ethereum?.request) {
        console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        return;
    }
    
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params : [ { chainId: '0x61' } ]
        });

    } catch (error) {
        console.error(error);
    }
}