import routes from './config/routes'
import {BrowserRouter, useRoutes} from 'react-router-dom'

const AppRouter = () => {
  return useRoutes(routes)
}

function App() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  )
}

export default App;
