import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import vector from '../assets/img/Vector.png'
import { AppContext } from '../layouts/Layout'
import Dashboard from '../pages/Dashboard'
import { switchChain } from '../utils'
import { RiCoinLine } from "react-icons/ri";
import {AiOutlineClockCircle} from "react-icons/ai";
import {BiLogOut} from "react-icons/bi"





export default function Connected ({setAddress, setOnConnect}) {
    const {address} = useContext(AppContext)
    const navigate = useNavigate()

    

    const formatAddress = (address) => {
        return address.substr(0,5) + '...' + address.substr(address.length-5)
    }


    useEffect(() => {
        window.ethereum.on('accountsChanged', (accounts) => {
            if (accounts.length === 0) {
                setAddress('')
                navigate("/")
                setOnConnect(false)
            }
            else {
                setAddress(accounts[0])
                setOnConnect(true)
            }
        })

    },[address] )

    useEffect(() => {
        if(address.length > 0) {
            switchChain()
        }
    },)

    const handleDisconnect = () => {
        setAddress('')
        setOnConnect(false)
    }

    const location = useLocation();
    const [handleClick, setHandleClick] = useState ("claim")

    useEffect(() => {
        if(address.length > 0 && location.pathname !== "/") {
            if(location.pathname === '/user/activity') {
                setHandleClick("activity")
            }
        
            else if (location.pathname === '/user/claim-token') {
                setHandleClick("claim")
            }

            else if (location.pathname === '/pool') {
                setHandleClick("pool")
            }
        } 
    }, [address])

    return (
      <>
        <div className="text-center top-sidebar">
          <div>
            <img src={vector} alt="" />
          </div>
          <div className="wallet-box text-center">
            <p className="fw-7 wallet-label m-0 text-white">Wallet Address</p>
            <p className="mb-0 wallet-address fw-7">{formatAddress(address)}</p>
          </div>
        </div>
        <div className="pt-5 text-white bot-sidebar">
          <div className={handleClick === "claim" ? "box-show" : "box-hide "}>
            <Link
              to="user/claim-token"
              className="d-flex flex-column align-items-center"
              onClick={() => setHandleClick("claim")}
            >
              <RiCoinLine className="page-icon" />
              <div
                style={
                  handleClick === "claim"
                    ? { color: "#ffffff" }
                    : { color: "#887878" }
                }
              >
                Claim Token
              </div>
            </Link>
          </div>

          <div
            className={handleClick === "activity" ? "box-show " : "box-hide "}
          >
            <Link
              to="user/activity"
              className="d-flex flex-column align-items-center"
              onClick={() => setHandleClick("activity")}
            >
              <AiOutlineClockCircle className="page-icon" />
              <div
                style={
                  handleClick === "activity"
                    ? { color: "#ffffff" }
                    : { color: "#887878" }
                }
                id="activity"
              >
                Activity
              </div>
            </Link>
          </div>

          <div
            className={handleClick === "pool" ? "box-show " : "box-hide "}
          >
            <Link
              to="pool"
              className="d-flex flex-column align-items-center"
              onClick={() => setHandleClick("pool")}
            >
              <div
                style={
                  handleClick === "pool"
                    ? { color: "#ffffff" }
                    : { color: "#887878" }
                }
                id="activity"
              >
                Pool
              </div>
            </Link>
          </div>

          <Link
            to="/"
            path={<Dashboard />}
            className="logout d-flex gap-1"
            onClick={handleDisconnect}
          >
            <BiLogOut className="page-icon" />
            <div className="text-white">Logout</div>
          </Link>
        </div>
      </>
    );
}