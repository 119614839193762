import React, { useEffect, useState } from "react";
import { formatDate, formatEther } from "../../utils";

export default function PoolListItem({
  contract,
  index,
})
{
  const [amount, setAmount] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
      contract.lockListByTime(index).then((data) => {
        setAmount(formatEther(data.amount));
        setTime(formatDate(data.releaseDate));
    });
  });

  return (
    <>
      <tr>
        <th scope="row">{index}</th>
        <td>{amount}</td>
        <td>{time}</td>
      </tr>
    </>
  );
}
