import { ethers } from "ethers";
import { createContext,  useEffect,  useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Sidebar from "../components/Sidebar"
import TopBar from "../components/TopBar"
import Vik from "../config/Vik.json"
import { formatDate } from "../utils";



export const AppContext = createContext();

export default function Layout() {

    const [address, setAddress] = useState('');
    const [onConnect, setOnConnect] = useState(true)
    const [tgeTime, setTgeTime] = useState('')
    const location = useLocation()
    const navigate = useNavigate()

  
    const checkInstalledMetamask = () => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            
            const contract = new ethers.Contract(Vik.address, Vik.abi, signer);
            
            if (signer) {
                contract.tge().then((result) => {
                    setTgeTime(formatDate(result))
                })
            }
        }
    }

    useEffect(checkInstalledMetamask, [])


    return (
        <div className="row main" >
            <AppContext.Provider value={{address, onConnect,setAddress, onConnect}} >
                <div className="col-md-3 border-end pe-0 sidebar">
                    <Sidebar setAddress={setAddress} setOnConnect={setOnConnect} />
                </div>
                <div className="col-md-9 p-0">
                    <TopBar />
                    <div className="content d-flex flex-column position-relative">
                        {
                            location.pathname !== "/" && 
                                <div className="position-absolute mt-1 ms-1 fw-7 fs-14 text-white">TGE: {tgeTime}</div>
                            
                        }
                        <Outlet />
                    </div>
                </div>
            </AppContext.Provider>
        </div>
    )
}

