import React, { useContext, useEffect, useState } from "react"
import { Button, Dropdown, Pagination,} from "antd";
import { isReleased } from "./ListItem";
import { UserContext } from "../layouts/UserLayout";



export default function List({list, size}) {

  const {pool} = useContext(UserContext)
  const [page, setPage] = useState(0)

  const handleChange = (page) => {
    setPage(page-1)
  }
    
  const listDisplay = () => {
    let arr = []
    for(let i = page*10; i < page*10+10; i++)
      arr.push(list[i])
    return arr
  }

  useEffect(() => {
    setPage(0)
  }, [pool])
 
  return (
    list && (
      <>
        <table className="table text-white mt-3 ms-2">
          <thead className="">
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Realease Date</th>
                  <th scope="col">Handle</th>
              </tr>
          </thead>
          <tbody>
            {
              listDisplay()
            }
            <tr>
              <td colSpan={4} className="text-center text-white border-bottom-none">
                <Pagination 
                  onChange={handleChange} 
                  showQuickJumper 
                  current={page+1} 
                  total={size} 
                  showTotal={size => `Total ${size} items`}
                  pageSize={10}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
    )
}

