import React, { createContext, useContext, useEffect, useState } from 'react'
import { AppContext } from '../layouts/Layout'
import { Menu, Dropdown, Button } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { formatEther } from '../utils'
import { Contract, ethers } from "ethers"
import { Pools } from '../config'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

export const UserContext = createContext()

export default function UserLayout() {

    const {address, setAddress, onConnect} = useContext(AppContext)
    const [availableAmount, setAvailableAmount] = useState(0)
    const [locked, setLocked] = useState(0)
    const [minted, setMinted] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [pool, setPool] = useState(0);
    const [contract, setContract] = useState(null);
    const navigate = useNavigate()

    if(!address) {
        navigate('/');
    }

    const provider = (window.ethereum) ? new ethers.providers.Web3Provider(window.ethereum) : null
    const signer = provider ? provider.getSigner() : null


    useEffect(() => {
        if(address) {
            const currentContract = new Contract(
                Pools[pool].address,
                Pools[pool].abi,
                signer,
            )
            setContract(currentContract)
        }
        setAvailableAmount(0);
        setLocked(0)
        setMinted(0);
        setTotal(0);
    }, [pool, address])

    

    const menu = (
        <Menu
            selectable
            defaultSelectedKeys={0}
            onClick={e => setPool(e.key)}
            items={Pools.slice(0,3)}
        />
      )

    const getData = () => {
        try {
            Promise.all([
                contract.getReceivedAmount(address),
                contract.getAvailableAmount(address),
                contract.getLockedFullAmount(address),
            
            ]).then((results) => {
                setMinted(formatEther(results[0]));
                setAvailableAmount(formatEther(results[1]));
                setTotal(formatEther(results[2]));
                let num1 = Number(formatUnits(results[0]));
                let num2 = Number(formatUnits(results[1]));
                let num3 = Number(formatUnits(results[2]));
                let res = num3 - (num1 + num2)
                setLocked(formatEther(parseUnits(res.toString())));
            });
        } catch (error) {
            console.log(error);
        }
    }
    


    useEffect(() => {
        
        if(!address) return;

        if (!contract) return;
        
        if ([0, 1, 2].includes(+pool)) {
            getData()
            return
        }
    }, [contract, loading])

    useEffect(() => {
        try {
            if (address.length && contract) {
                setAvailableAmount(0)
                setLocked(0)
                setMinted(0)
                getData()
            } 
        } catch {
            setAvailableAmount(0);
            setLocked(0);
            setMinted(0);
        }
    }, [address])

    const connectWallet = async () => {
        if (!window.ethereum?.request) {
            window.open('https://metamask.io/download.html')
            console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
            return;
        }
            
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        setAddress(accounts[0]);
    }

    useEffect(() => {
        if(onConnect) {
            connectWallet()
        }
    }, [])



  return (
      <UserContext.Provider value={{
          contract,
          pool,
          loading,
          setLoading,
          setAvailableAmount,
          setLocked,
          setMinted
      }}>  
          <>
            <div className="pool-btn">
                <Dropdown overlay={menu} placement="bottom" >
                    <Button>
                        <div className="fw-5">{Pools[pool].label}</div>
                    </Button>
                </Dropdown>
            </div>
            <div style={{width:'100%'}}>
                <div className="display-token m-auto">
                    <div className="display-token-box">
                        <div>Claimed</div>
                        <div className="mt-1">{minted}</div>
                    </div>
                    <div className="display-token-box">
                        <div>Available</div>
                        <div className="mt-1">{availableAmount}</div>
                      </div>
                      <div className="display-token-box">
                          <div>Locked</div>
                          <div className="mt-1">{locked}</div>
                        </div>
                    <div className='display-token-box'>
                        <div>Total</div>
                        <div className='mt-1'>{total}</div>
                    </div>
                </div>
                </div>
            <Outlet />
        </>
    </UserContext.Provider>
  )
}
