import PartnershipPool from './PartnershipPool.json'
import PrivatePool from './PrivatePool.json'
import SeedPool from './SeedPool.json'
import CoreTeamPool from './CoreTeamPool.json'
import AdvisorPool from './AdvisorPool.json'
import LiquidityPool from './LiquidityPool.json'
import MarketingPool from "./MarketingPool.json";
import PublicPool from "./PublicPool.json";
import ReservePool from "./ReservePool.json";
import RewardPool from "./RewardPool.json";
import StakingPool from "./StakingPool.json";
import Vik from "./Vik.json";


export const Pools = [
    {...PartnershipPool, key: 0, label: 'Partnership Pool'},
    {...PrivatePool, key: 1, label: 'Private Pool'},
    {...SeedPool, key: 2, label: 'Seed Pool'},
    {...CoreTeamPool, key: 3, label: 'Core Team Pool'},
    { ...AdvisorPool, key: 4, label: 'Advisor Pool' },
    { ...LiquidityPool, key: 5, label: 'Liquidity Pool' },
    { ...MarketingPool, key: 6, label: 'Marketing Pool' },
    { ...PublicPool, key: 7, label: 'Public Pool' },
    { ...ReservePool, key: 8, label: 'Reserve Pool' },
    { ...RewardPool, key: 9, label: 'Reward Pool' },
    { ...StakingPool, key: 10, label: 'Staking Pool' },
]