import { Button, Dropdown, Menu } from 'antd'
import { Contract, ethers } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PoolList } from '../components/Pool/PoolList'
import PoolListItem from '../components/Pool/PoolListItem'
import { Pools } from '../config'
import { AppContext } from '../layouts/Layout'
import { formatEther } from '../utils'

export default function Pool() {

    const {address, setAddress, onConnect} = useContext(AppContext)
    const [lockedFullAmount, setLockedFullAmount] = useState(0)
    const [minted, setMinted] = useState(0)
    const [total, setTotal] = useState(0)
    const [pool, setPool] = useState(3);
    const [contract, setContract] = useState(null);
    const navigate = useNavigate()


    const provider = ((window.ethereum) ? new ethers.providers.Web3Provider(window.ethereum): null);
    const signer = provider ? provider.getSigner() : null
      
    const [list, setList] = useState([])
    const [size, setSize] = useState(0)


    useEffect(() => {
      if(address) {
          const currentContract = new Contract(
              Pools[pool].address,
              Pools[pool].abi,
              signer,
          )
        setContract(currentContract)
      }
    
      setMinted(0);
      setLockedFullAmount(0);
      setTotal(0);
    }, [pool, address])


      const menu = (
          <Menu
              selectable
              defaultSelectedKeys={3}
              onClick={e => setPool(e.key)}
              items={Pools.slice(3)}
          />
          )


      const getData = () => {
          try {
              Promise.all([
                contract.receivedAmount(address),
                contract.contribute(address),
                contract.getLockedListSize(),
              ]).then((results) => {
                setMinted(formatEther(results[0]));
                setTotal(formatEther(results[1]));
                let num1 = Number(formatUnits(results[0]));
                let num2 = Number(formatUnits(results[1]));
                let res = num2 - num1;
                setLockedFullAmount(formatEther(parseUnits(res.toString())));
                let arr = [];
                if(results[1].toNumber() !== 0 )
                {
                    setSize(results[2].toNumber());
                    for (let i = 0; i < results[2].toNumber(); i++) {
                      arr.push(
                        <PoolListItem key={i} index={i} contract={contract} />
                      );
                  }
                }

                setList(arr);
              });
          }
          catch (error) {
              setLockedFullAmount(0)
              setMinted(0);
              setTotal(0)
              setSize(0);
              setList([])
          }
          
      }

      useEffect(() => {
      
          if(!address) return;

          if(!contract) return;
          
          if(![0, 1, 2].includes(+pool)) {
              getData()
              return;
          }
      }, [contract])


      const connectWallet = async () => {
          if (!window.ethereum?.request) {
              window.open('https://metamask.io/download.html')
              console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
              return;
          }
              
          const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
          });
          setAddress(accounts[0]);
      }


      useEffect(() => {
          if(onConnect) {
              connectWallet()
          }
      }, [])


    
    
  return (
    <>
      <div className="pool-btn">
        <Dropdown overlay={menu} placement="bottom">
          <Button>
            <div className="fw-5">{Pools[pool].label}</div>
          </Button>
        </Dropdown>
      </div>
      <div style={{ width: "100%" }}>
        <div className="display-token m-auto">
          <div className="display-token-box">
            <div>Claimed</div>
            <div className="mt-1">{minted}</div>
          </div>
          <div className="display-token-box">
            <div>Locked</div>
            <div className="mt-1">{lockedFullAmount}</div>
          </div>
          <div className="display-token-box">
            <div>Total</div>
            <div className="mt-1">{total}</div>
          </div>
        </div>
      </div>
      <PoolList list={list} size={size} pool={pool} />
    </>
  );
}
