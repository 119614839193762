import { Button, notification, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { formatDate, formatEther } from '../utils'

export default function ListItem({contract, id, index, setLoading, loading}) {

   const [amount, setAmount] = useState('0')
   const [time, setTime] = useState('0')
   const [released, setReleased] = useState(false)
   const [releasedable, setReleasedable] = useState(true)
   const [buttonLoading, setButtonLoading] = useState(false)


   useEffect(() => {
        contract.lockListByTime(id, index).then((data) => {
            setAmount(formatEther(data.amount))
            setTime(formatDate(data.releaseDate))
            const d = new Date()
            let current = d.getTime()
            setReleasedable(current.toString() > data.releaseDate.toString())
            setReleased(data.isRelease)
        })
   },)

   const isReleased = async () => {
        
        setButtonLoading(true)

        const result = await contract.mintAndRelease(index)
        if(result)
        {
            notification.open({
                message:    'Transaction request',
                description:  "Please wait for transaction request"
              });
        }
        const receipt = await result.wait()
        if(receipt)
        {   
            notification.open({
                message: 'Transaction success',
                description: 'Transaction success',
                onClick: () => {
                        window.open(`https://testnet.bscscan.com/tx/${result.hash}`)
                    } 
            })
        }

        
        setButtonLoading(false)
        setReleased(true)
        setLoading(true)

    }

    return (
        <tr>
            <th scope='row'>{index+1}</th>
            <td>{amount}</td>
            <td>{time}</td>
            <td>
                {
                    releasedable ? (
                        released ? <div className='fw-5'>Claimed</div>
                        : 
                        <Popconfirm
                            title="Are you sure to release ?"
                            onConfirm={isReleased}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size="small" ghost='true' type='primary' loading={buttonLoading}>Claim</Button>
                        </Popconfirm>
                    ) : <div className='fw-5'>Unreleasedable</div>
                    
                }
            </td>
        </tr>
    )
}

