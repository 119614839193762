import { useNavigate } from "react-router-dom";
import vector from "../assets/img/Vector.png";
import { getWallet } from "../utils";



export default function BeforeConnect ({setAddress}) {
    
    const navigate = useNavigate()
    const handleConnect = async () => {
        const accounts = await getWallet()
        setAddress(accounts[0]);
        if(accounts[0].length > 0)
            navigate("user/claim-token");
    }

    
    return (
        <div className="text-center top-sidebar">
            <div>
                <img src={vector} alt="" />
            </div>
            <button className="wallet-box text-center" onClick={handleConnect} >
                <div className="connect-text m-0 text-white">Connect Wallet</div>
            </button>
        </div>
    )
}
