import { useContext } from "react"
import { AppContext } from "../layouts/Layout"
import BeforeConnect from "./BeforeConnect"
import Connected from "./Connected"

export default function Sidebar ({setAddress, setOnConnect}) {

    const {address} = useContext(AppContext)

    return address.length > 0 ? <Connected setAddress={setAddress} setOnConnect={setOnConnect}/> : <BeforeConnect  setAddress={setAddress} />
}