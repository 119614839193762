export default function TopBar () {
    return (
        <div className="topbar text-brown">
            <div className="menu fw-7 ">
                <a href="https://vilik.io/" target="_blank">HOME</a>
                <a href="#" >HOW TO CONNECT</a>
                <a href="https://www.facebook.com/vilik.visionlink/" target="_blank">SUPPORT</a>
            </div>
        </div>
    )
}