
import React, { useEffect, useRef, useState } from 'react'
import { formatDate, formatEther } from '../utils'

export default function ActivityListItem({contract, id, pool, index, currentIndex}) {

  const [amount, setAmount] = useState()
  const [time, setTime] = useState()
  useRef()

  useEffect(() => {
      contract.lockListByTime(id, index).then((data) => {
        setAmount(formatEther(data.amount))
        setTime(formatDate(data.releaseAt))
    })
  },)

  return (
    <>
      <tr>
        <th scope='row'>{currentIndex}</th>
        <td>
            {amount}
        </td>
        <td>{time}</td>
        <td>
            {pool}
        </td>
      </tr>
    </>
  )
}
