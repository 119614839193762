import React, { useContext, useEffect, useState } from 'react'
import ActivityListItem from '../components/ActivityListItem'
import ActivityList from '../components/ActivityList'
import { UserContext } from '../layouts/UserLayout'
import { AppContext } from '../layouts/Layout'
import { Pools } from '../config'
import { useNavigate } from 'react-router-dom'


export default function Activity () {

    const {address} = useContext(AppContext)
    const {
      pool,
      contract,
      setAvailableAmount,
      setLockedFullAmount,
      setMinted,
    } = useContext(UserContext);
    const [size, setSize] = useState(0)
    const [activityListData, setActivityListData] = useState([]);
    const navigate = useNavigate()


    const getActivityListData = async () => {
        let arr = []
        let currentIndex = 1
        const id =  await contract.findId(address);
        const fullSize = await contract.getLockedListSize(id)
        for(let i = 0; i < fullSize.toNumber(); i++)
            {
                const minted = await contract.isReleased(id, i)
                if(minted)
                    {
                        arr.push( <ActivityListItem key={i} contract={contract} id={id} pool={Pools[pool].label} index={i} currentIndex={currentIndex} /> )
                        currentIndex++
                    }     
            }
        setActivityListData(arr)
        setSize(currentIndex - 1)
    }

    useEffect(() => {
        if (address.length && contract) {
            getActivityListData()
        }
    }, [contract, address])



    return (
        <>
            <ActivityList item={activityListData} size={size} /> 
        </>
        
    )
}
