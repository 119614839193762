import Layout from "../layouts/Layout";
import UserLayout from "../layouts/UserLayout";
import Activity from "../pages/Activity";
import ClaimToken from "../pages/ClaimToken";
import Dashboard from "../pages/Dashboard";
import Pool from "../pages/Pool";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "user",
        element: <UserLayout />,
        children: [
          {
            path: "activity",
            element: <Activity />,
          },
          {
            path: "claim-token",
            element: <ClaimToken />,
          },
        ],
      },
      {
        path: "pool",
        element: <Pool />,
      },
    ],
  },
];

export default routes