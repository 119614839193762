import List from "../components/List";
import { AppContext } from "../layouts/Layout"
import ListItem from "../components/ListItem"
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../layouts/UserLayout";
import { useNavigate } from "react-router-dom";


export default function ClaimToken () {

    const {address} = useContext(AppContext)
    const {
      contract,
      loading,
      setLoading,
    } = useContext(UserContext);
    const [lockListSize, setLockListSize] = useState(0)
    const [list, setList] = useState([])
    const navigate = useNavigate()


    const getData = async () => {
        const id = await contract.findId(address)
        const size = await contract.getLockedListSize(id);
        setLockListSize(size.toNumber());
        
        let arr = []

        for(let i = 0; i < size.toNumber(); i++) {
            arr.push(<ListItem key={i} contract={contract} id={id} index={i} setLoading={setLoading} loading={loading} />)
        }
        setList(arr)
        
    }

    useEffect(() => {
        if (address.length > 0 && contract) {
            getData()
        } 
        setLoading(false)
    }, [address, contract, loading])



    return (
        <>
            <div className="tabledata">
                <List list={list} size={lockListSize}/>
            </div>
        </>
    )
}