import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/img/connect-wallet-icon.png'
import { AppContext } from '../layouts/Layout'
export default function Dashboard () {

    const navigate = useNavigate()
    const {onConnect} = useContext(AppContext)
    if(onConnect) {
        navigate("user/claim-token")
    }
    
    return (
        <>
            <img src={logo} className="pt-5 dashboard-img" alt=""/>
            <div className='fs-16 fw-7 text-center text-brown content-below-img-dashboard'>LET CONNECT YOUR WALLET</div>
        </>
    )
    
}