import { Pagination } from 'antd'
import React, { useState } from 'react'

export default function ActivityList({item, size}) {

const [page, setPage] = useState(0)

  const handleChange = (page) => {
    setPage(page-1)
  }
    
  const listDisplay = () => {
    let arr = []
    for(let i = page*10; i < page*10+10; i++) {
        arr.push(item[i])
    }
    return arr
  }


  return (
    <div className="tabledata">
        <table className="table text-white mt-3 ms-2">
            <thead className="">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Claimed Date</th>
                    <th scope="col">Pool</th>
                </tr>
            </thead>
            <tbody>
            { 
                listDisplay()
            }
            <tr>
              <td colSpan={4} className="text-center text-white border-bottom-none">
                <Pagination 
                  onChange={handleChange} 
                  showQuickJumper 
                  current={page+1} 
                  total={size} 
                  showTotal={size => `Total ${size} items`}
                  pageSize={10}
                />
              </td>
            </tr>
            </tbody>
        </table>
    </div>
  )
}
